<template>
  <div class="bidding">
    <div class="main">
      <el-card class="box-card">
        <div class="main-body">
          <el-card>
            <div class="infomain">
              <div class="infoimg"  style="text-align:center">
                <img :src="imgs" alt="" style="height:60%;width:80%;text-align:center;display:block;margin-left:10%;margin-top:5%">
                <img :src="imgs" alt="" style="height:60px;width:60px;margin:10px 5px">
                <img :src="imgs" alt="" style="height:60px;width:60px;margin:10px 5px">
                <img :src="imgs" alt="" style="height:60px;width:60px;margin:10px 5px">
                <img :src="imgs" alt="" style="height:60px;width:60px;margin:10px 5px">
              </div>
              <!-- 详情  右侧 -->
              <div class="infogoods">
                <div style="font-size:30px;font-weight:700;magin-left:50px;margin-top:25px;">
                  大兴废旧钢铁公司
                </div>
                <div>
                  <div>当前价：<span style="color:red;">￥</span><span style="color:red;font-size:36px;font-weight:800;">8848</span></div>
                </div>
                <div>
                  <div style="display:block">距开始：<span style="color:red;font-size:22px;font-weight:800;">1天23小时35分26秒</span></div>
                </div>
                <div>
                  <div style="border:1px solid #999999;width:800px;height:150px;margin:0" class="mysmk">
                    <div  style="margin-left:30px;margin-bottom:10px;font-size:22px;font-weight:600;">出价</div>
                    <div style="margin-left:30px">
                    <el-button  icon="el-icon-remove-outline" style="display: inline-block;"></el-button>
                    <el-input v-model="input" placeholder="请输入" style="display: inline-block;width:200px"></el-input>
                    <el-button  icon="el-icon-circle-plus-outline" style="display: inline-block;"></el-button>
                    <el-button style="background:red;width:250px;color:white">我要报名</el-button>
                    </div>
                    <div>
                      <el-button style="margin-top:-250px;background:#3071e9;width:80px;color:white;float:right">未报名</el-button>
                    </div>
                  </div>
                </div>
                <div class="rgoods">
                  <ul v-for="(item,index) in listform" :key="index" class="listform">
                    <li>{{item.name}}{{item.value}}</li>
                  </ul>
                  <button style="color:red;margin-left:-120px">查看资质</button>
                </div>
              </div>
            </div>
          </el-card>
            <div class="main-title" style="text-align:center;font-size:20px;font-weight:500;margin-top:20px;">
              <el-card>竞买记录
                 <el-table
                    :data="tableData"
                    style="width: 100%"
                    :row-class-name="tableRowClassName">
                    <el-table-column
                      prop="name"
                      label="出价方式"
                      align="center"
                      width="180">
                    </el-table-column>
                    <el-table-column
                      prop="address"
                       align="center"
                      label="竞买号">
                    </el-table-column>
                     <el-table-column
                      prop="price"
                       align="center"
                      label="价格(元)">
                    </el-table-column>
                    <el-table-column
                      prop="date"
                      label="日期"
                       align="center"
                      width="180">
                    </el-table-column>
                     <el-table-column
                      prop="num"
                       align="center"
                      label="第几轮">
                    </el-table-column>
                  </el-table>
              </el-card>
            </div>
            <!-- // 竞价记录 -->
            <info></info>
         </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import infoxtemp from '../../commons/info_bidding' //竞价资产

export default {
  components:{
    info: infoxtemp
  },
  data() {
    return {
      input: 8840,
      icaddr:require('../../assets/imgs/ic_address.png'),
       tableData: [{
          date: '2021-10-02',
          name: '网路竞价',
          address: '001',
          price: '250',
          num:'2'
        }],
        imgs:require('../../assets/imgs/gangcai1.png'),
      dataList: [],
      listform: [
        { name: "挂牌价:", type: "0", value: 8840 },
        { name: "加价幅度:", type: "1", value: 100 },
        { name: "类型:", type: "2", value: '网路竞价' },
        { name: "交易保证金:", type: "3", value: 3000 },
        { name: "评估价:", type: "4", value: 12500 },
        { name: "限时竞价周期:", type: "5", value: '5分钟/次' },
        { name: "优先购买权:", type: "5", value: '' },
        { name: "服务费:", type: "5", value: 0 },
        { name: "保留价:", type: "5", value: '有/无' },
        { name: "增值税费:", type: "5", value: '' },
        { name: "处置单位:", type: "5", value: '' },
        { name: "咨询方式:", type: "5", value: '' },
        { name: "拍卖师:", type: "5", value: '' },
      ],
      //分页数据
      dataForm: {
        productid: '',
        delivery: '',
        outbidstate: '',
        splitstate: ''
      },
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      qualityTableStyle: {
        'background': 'whitesmoke',
        'font-weight': 'bold',
        'font-size': ' 12px',
        'font-family': "Microsoft YaHei",
        'color': '#333333',
        'text-align': 'center',
      },
      bidnames: '',//竞价场次名称
      timeDate: '',//开始结束时间
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // table样式
    tableRowClassName({rowIndex}) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
    //转换时间格式
    formateDate(datetime) {
      function addDateZero(num) {
        return (num < 10 ? "0" + num : num);
      }
      let d = new Date(datetime);
      let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate());
      return formatdatetime;
    },
    //搜索
    getSelect() {
      this.dataListLoading = true;
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      // this.$fetch('pctargetinfo/list', {
      //   'page': this.pageIndex,
      //   'limit': this.pageSize,
      //   'productid': this.dataForm.productid,
      //   'delivery': this.dataForm.delivery,
      //   'outbidstate': this.dataForm.outbidstate,
      //   'splitstate': this.dataForm.splitstate,
      //   'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]) +' ' + '00:00:00',
      //   'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1]) + ' ' + '23:59:59',
      //   'bidname': this.bidnames === '' || this.bidnames === null? '':this.bidnames,

      // }, true).then((data) => {
      //   if (data && data.code === 0) {
      //     this.dataList = data.page.list;
      //     this.totalPage = data.page.totalCount
      //   } else {
      //     this.dataList = []
      //     this.totalPage = 0
      //     this.$message({
      //       message: data.msg,
      //       type: 'error',
      //       showClose: 'true'
      //     })
      //   }
      // })


    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      //调用查询信息接口
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      //调用查询信息接口
      this.getDataList()
    },
    activated() {
      this.getDataList()
    },
    gotoDetails(targetid) {
      let queryData = {};
      queryData.targetid = targetid;
      this.$router.push({path: "/auction", query: queryData});
    },
    setProductid(val) {
      this.dataForm.productid = val
      //调用查询信息接口
      this.getDataList()
    },
    setPelivery(val) {
      this.dataForm.delivery = val
      //调用查询信息接口
      this.getDataList()
    },
    setOutbidstate(val) {
      this.dataForm.outbidstate = val
      //调用查询信息接口
      this.getDataList()
    },
    setSplitstate(val) {
      this.dataForm.splitstate = val
      //调用查询信息接口
      this.getDataList()
    },

  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/index.css";
@import "../../assets/css/page/Bidding.scss";

 .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #2c6add;
  }
  
.infomain{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  .infoimg{
    height: 500px;
    flex: 1;
    // border: 1px solid red;
  }
  .infogoods{
    flex: 2;
    // border: 1px solid red;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content:center;
    &>:nth-child(1){
      // border: 1px solid red;
      flex: 1;
    }
    &>:nth-child(2){
      flex: 1;
    }
    &>:nth-child(3){
      flex: 1;
    }
    &>:nth-child(4){
      flex: 2;
    }
    &>:nth-child(5){
      flex: 3;
    }
  }
} 
.mysmk{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content:center;
  &>div{
    
  }
}
.rgoods{
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  &>ul{
    width: calc(calc(100%/3)-20px);
  }
}
</style>
