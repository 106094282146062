<template>
  <div class="mainsheet">
    <!-- <div class="title">竞价资产</div> -->
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="box">
        <div class="smline"></div>
        <el-tab-pane label="重要提示" name="first"  >
           <div style="height:50vh"></div>
        </el-tab-pane>
        <el-tab-pane label="竞买公告" name="second" >
            <div style="height:50vh"></div>
        </el-tab-pane>
        <el-tab-pane label="竞买人须知" name="third" >
            <div style="height:50vh"></div>
          </el-tab-pane>
        <el-tab-pane label="标的物介绍" name="fourth" >
            <div style="height:50vh"></div>
          </el-tab-pane>
        <el-tab-pane label="相关附件" name="fourth" >
          <div style="height:50vh"></div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
export default {
   data() {
      return {
        activeName: 'first',
        dataList: [],
          dataForm: {
              productid: '',
              delivery: '',
              outbidstate: '',
              splitstate: ''
          },
      };
    },
    mounted(){
    },
    methods: {
      //竞价销售列表
            gethomeDataList() {
                this.$fetch('pctargetinfo/list', {
                    'page': this.pageIndexs,
                    'limit': this.pageSizes,
                    'productid': this.dataForm.productid,
                    'delivery': this.dataForm.delivery,
                    'outbidstate': '',
                    'splitstate': this.dataForm.splitstate,
                    'begintime': '',
                    'endtime': '',
                    'bidname': ''
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.dataList = data.page.list;
                      this.homeData=setTimeout(this.gethomeDataList,5000);
                    } else {
                        this.dataList = []
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
      // 切换title
      handleClick(tab, event) {
        // console.log(tab, event);
      }
    }
  };
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "../assets/css/page/info_bidding.scss";
</style>
